"use client";

import BankLogoComponent from "@portal/components/atoms/bankLogo";
import { FC } from "react";
import { ILogoButtonProps } from "./types";

const LogoButton: FC<ILogoButtonProps> = ({ centerId }) => (
  <button onClick={() => (window.location.href = "https://portal.norne.no")}>
    <BankLogoComponent centerId={centerId} />
  </button>
);

export default LogoButton;
