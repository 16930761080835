import { broadcastSignOut } from "./signOutBroadcast";

const signOut = async (advisor = false) => {
  broadcastSignOut();
  if (advisor) {
    window.location.href = "/api/auth/logout?advisor=true";
    return;
  }
  window.location.href = "/api/auth/logout";
};

export default signOut;
