"use client";

import { TextResources } from "@portal/assets/text/TextResources";
import { useSession } from "@portal/auth/client/SessionProvider";
import Typography from "@portal/components/atoms/v2/typography";
import { useRouter } from "next/navigation";

const HomeButton = () => {
  const { session } = useSession();
  const router = useRouter();

  if (session?.status === "authenticated") {
    return (
      <div className="flex space-x-4">
        <button
          onClick={() => {
            router.push("/");
          }}
          className="flex space-x-1"
        >
          <Typography>{TextResources.MENU_DASHBOARD}</Typography>
        </button>
      </div>
    );
  }

  return null;
};

export default HomeButton;
