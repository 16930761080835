import Icon from "@portal/components/atoms/icons";
import { TextResources } from "@portal/assets/text/TextResources";
import Typography from "@portal/components/atoms/v2/typography";

/**
 * Footer for Sparebanken Møre
 * @returns a footer
 */
export const PortalFooterSbm = () => (
  <div className="flex bg-[#01052F] w-full mt-auto text-[#f2f2f2]">
    <div className="flex flex-col sm:flex-row justify-start container mx-auto px-4 py-4">
      <div className="space-y-1 mb-7 sm:mb-0">
        <div>
          <Typography variant="H5">{TextResources.FOOTER_SBM_CONTACT_HEADER}</Typography>
        </div>
        <div>
          <Typography variant="Body">{TextResources.FOOTER_SBM_OPEN_HOURS}</Typography>
        </div>
        <a href="tel:+4770113000">
          <div className="flex items-center mt-1 sm:hidden">
            <div className="flex pr-2">
              <Icon name="phoneIcon" size="xsmall" />
            </div>
            <Typography variant="Body">{TextResources.FOOTER_SBM_PHONE_NUMBER}</Typography>
          </div>
        </a>
        <div className="hidden sm:flex items-center mt-1">
          <div className="flex pr-2">
            <Icon name="phoneIcon" size="xsmall" />
          </div>
          <Typography variant="Body">{TextResources.FOOTER_SBM_PHONE_NUMBER}</Typography>
        </div>
      </div>
    </div>
  </div>
);
