"use client";

import Icon from "@portal/components/atoms/icons";
import { TextResources } from "@portal/assets/text/TextResources";
import { useSession } from "@portal/auth/client/SessionProvider";
import signOut from "@portal/auth/client/api/signOut";
import Typography from "@portal/components/atoms/v2/typography";

const LogoutButton = () => {
  const { session } = useSession();

  if (session?.status === "authenticated") {
    return (
      <div className="flex space-x-4">
        <button
          onClick={() => {
            signOut();
          }}
          className="flex space-x-1"
        >
          <Icon name="arrowRightOnRectangleIcon" size="small" />
          <Typography>{TextResources.MENU_LOGOUT}</Typography>
        </button>
      </div>
    );
  }

  return null;
};

export default LogoutButton;
