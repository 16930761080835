import LogoutButton from "../logoutButton";
import LogoButton from "../logoButton";
import HomeButton from "../homeButton";

interface Props {
  centerId: string;
}

const Header = ({ centerId }: Props) => (
  <div className="container mx-auto">
    <div className="flex justify-between items-center">
      <div className="flex items-end space-x-4">
        <LogoButton centerId={centerId} />
        <div className="pb-[10px]">
          <HomeButton />
        </div>
      </div>
      <LogoutButton />
    </div>
  </div>
);

export default Header;
